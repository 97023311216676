// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
//import auth from './authentication'
import auth from './reducers/auth'
import message from './reducers/message'

import users from '../views/apps/user/store'
import ads from '@src/views/apps/ads/store'
import shops from '../views/apps/shops/store'
import tickets from '../views/apps/support/store'
import crawler from '../views/data/store'
import email from '@src/views/apps/support/email/store'
import chatbot from '../views/chatbot/store'
import chat from '@src/views/chatbot/chat/store'
import offers from '@src/views/payment/offers/store'
import orders from '@src/views/payment/orders/store'
import payment from '@src/views/payment/transactions/store'

const rootReducer = {
  auth,
  message,
  navbar,
  layout,
  users,
  ads,
  shops,
  tickets,
  crawler,
  email,
  chatbot,
  chat,
  offers,
  orders,
  payment
}

export default rootReducer