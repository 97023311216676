
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await instance.get('/users')
  return response.data.allData
})

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const { page, perPage, q, status } = params
  const response = await instance.get(`/users?page=${page}&&limit=${perPage}&&q=${q}&&status=${status}`)
  return {
    params,
    data: response.data.data,
    total: response.data.total,
  }
})

// Get, Add, Delete, Suspend user
export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await instance.get(`/users/${id}`)
  return response.data
})

export const suspendUser = createAsyncThunk('appUsers/suspendUser', async (id, { dispatch, getState }) => {
  instance.put(`/users/${id}`, { active: false })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const activateUser = createAsyncThunk('appUsers/activateUser', async (id, { dispatch, getState }) => {
  instance.put(`/users/${id}`, { active: true })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await instance.post('/users', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

// Delete ad
export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await instance.delete(`/users/${id}`)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

// Delete multiple ads
export const deleteUsers = createAsyncThunk('appUsers/deleteUser', async (ids, { dispatch, getState }) => {
  const result = await instance.delete(`/all/users`, { data: { ids } })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return result.data.status
})

/* export const getUserAds = createAsyncThunk('appUsers/getUserAds', async (id) => {
  const response = await instance.get(`/users/ads/${id}`);
  return response.data
}) */

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    params: {},
    data: [],
    allData: [],
    total: {},
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
