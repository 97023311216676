
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'


export const getAllData = createAsyncThunk('appAds/getAllData', async () => {
  //const response = await axios.get(`${API_URL}`)
  const response = await instance.get('/ads');
  return response.data.allData
})
export const getData = createAsyncThunk('appAds/getData', async (params) => {
  const { page, perPage, q, status, sold } = params
  const response = await instance.get(`/ads?page=${page}&&limit=${perPage}&&q=${q}&&status=${status}&&sold=${sold}`)
  return {
    params,
    data: response.data.data,
    total: response.data.total
  }
})

// Get, Add, Delete, Suspend ads
export const getAd = createAsyncThunk('appAds/getAd', async (id) => {
  const response = await instance.get(`/ads/${id}`)
  return response.data
})
export const suspendAd = createAsyncThunk('appAds/suspendAd', async (id, { dispatch, getState }) => {
  await instance.put(`/ads/${id}`, { visible: false })
  await dispatch(getData(getState().ads.params))
  await dispatch(getAllData())
  return id
})
export const activateAd = createAsyncThunk('appAds/activateAd', async (id, { dispatch, getState }) => {
  await instance.put(`/ads/${id}`, { visible: true })
  await dispatch(getData(getState().ads.params))
  await dispatch(getAllData())
  return id
})

export const approveAd = createAsyncThunk('appAds/approveAd', async id => {
  await instance.put(`/ads/adstate/${id}`, { validated: true })
  return id
})

export const addAd = createAsyncThunk('appAds/addAd', async (ad, { dispatch, getState }) => {
  await instance.post('ads', ad)
  await dispatch(getData(getState().ads.params))
  await dispatch(getAllData())
  return ad
})
export const deleteAd = createAsyncThunk('appAds/deleteAd', async (id, { dispatch, getState }) => {
  await instance.delete(`/ads/${id}`)
  await dispatch(getData(getState().ads.params))
  await dispatch(getAllData())
  return id
})

export const appAdsSlice = createSlice({
  name: 'appAds',
  initialState: {
    params: {},
    data: [],
    total: {},
    allData: [],
    selectedAd: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getAd.fulfilled, (state, action) => {
        state.selectedAd = action.payload
      })
  }
})

export default appAdsSlice.reducer
