
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'

export const getAllData = createAsyncThunk('appShops/getAllData', async () => {
  const response = await instance.get('/shops')
  //return response.data.allData
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const { page, perPage, q } = params
  const response = await instance.get(`/shops?page=${page}&&limit=${perPage}&&q=${q}`)
  return {
    params,
    data: response.data.data,
    total: response.data.total,
  }
})


// Get, Add, Delete, Suspend shop
export const getShop = createAsyncThunk('appShops/getShop', async id => {
  const response = await instance.get(`/users/${id}`)
  return response.data
})

export const addShop = createAsyncThunk('appShops/addShop', async (shop, { dispatch, getState }) => {
  await instance.post('/users', shop)
  await dispatch(getData(getState().shops.params))
  await dispatch(getAllData())
  return shop
})
export const deleteShop = createAsyncThunk('appShops/deleteShop', async (id, { dispatch, getState }) => {
  await instance.delete(`/users/${id}`)
  await dispatch(getData(getState().shops.params))
  await dispatch(getAllData())
  return id
})

export const suspendShop = createAsyncThunk('appShops/suspendShop', async (id, { dispatch, getState }) => {
  await instance.put(`/users/${id}`, { active: false })
  await dispatch(getData(getState().shops.params))
  await dispatch(getAllData())
  return id
})
export const activateShop = createAsyncThunk('appShops/activateShop', async (id, { dispatch, getState }) => {
  await instance.put(`/users/${id}`, { active: true })
  await dispatch(getData(getState().shops.params))
  await dispatch(getAllData())
  return id
})

export const appShopsSlice = createSlice({
  name: 'appShops',
  initialState: {
    params: {},
    data: [],
    allData: [],
    total: {},
    selectedShop: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.allData
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getShop.fulfilled, (state, action) => {
        state.selectedShop = action.payload
      })
  }
})

export default appShopsSlice.reducer
