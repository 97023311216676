// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'

export const crawlMra = createAsyncThunk('crawler/crawlMra', async () => {
    const response = await instance.get('mra')
    return response.data
})

export const addMraPost = createAsyncThunk('crawler/addPost', async (post) => {
    const response = await instance.post('/mra', {
        title: post.post.title,
        description: post.post.description,
        price: post.post.price,
        advertiser: post.post.advertiser,
        phone: post.post.phone,
        url: post.images
    })
    return response.data
})

export const crawlAvito = createAsyncThunk('crawler/crawlAvito', async () => {
    const response = await instance.get('/avito')
    return response.data
})

export const addAvitoPost = createAsyncThunk('crawler/addAvitoPost', async (post, { dispatch, getState }) => {
    const response = await instance.post('/avito', {
        title: post.post.name,
        description: post.post.description,
        price: post.post.offers.price,
        advertiser: post.postExtra.seller_name,
        phone: post.postExtra.ad_phone_number,
        url: post.post.image
    })
    await dispatch(crawlAvito())
    return response.data
})

export const crawlerSlice = createSlice({
    name: 'crawler',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(crawlMra.fulfilled, (state, action) => {
                state.data = action.payload
            })
            .addCase(crawlAvito.fulfilled, (state, action) => {
                state.data = action.payload
            })
            .addCase(addMraPost.fulfilled, (state, action) => {
                state.data = action.payload
            })
            .addCase(addAvitoPost.fulfilled, (state, action) => {
                state.data = action.payload
            })
    }
})

export default crawlerSlice.reducer