
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'

export const getData = createAsyncThunk('orders/getData', async () => {
    const response = await instance.get('/orders')
    return response.data
})

export const getOrder = createAsyncThunk('orders/getOrder', async (id) => {
    const response = await instance.get(`/orders/${id}`)
    return response.data
})

export const addOrder = createAsyncThunk('orders/addOrder', async (order, { dispatch, getState }) => {
    await instance.post('/orders', order)
    await dispatch(getData(getState().orders.params))
    return order
})

export const deleteOrder = createAsyncThunk('orders/deleteOrder', async (id, { dispatch, getState }) => {
    await instance.delete(`/orders/${id}`)
    await dispatch(getData(getState().orders.params))
    return id
})

export const getAllUsers = createAsyncThunk('orders/getAllUsers', async () => {
    const response = await instance.get('/all/users')
    return response.data.result
})

export const getAllAds = createAsyncThunk('orders/getAllAds', async () => {
    const response = await instance.get('/all/ads')
    return response.data.result
})

export const subCatOptions = createAsyncThunk('orders/subCatOptions', async () => {
    const response = await instance.get('/subcat')
    return response.data.result
})

export const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        params: {},
        data: [],
        selectedOrder: null,
        result: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
            })
            .addCase(getOrder.fulfilled, (state, action) => {
                state.selectedOrder = action.payload
            })
            .addCase(subCatOptions.fulfilled, (state, action) => {
                state.result = action.payload.data
            })
    }
})

export default ordersSlice.reducer
