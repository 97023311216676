
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'

export const getData = createAsyncThunk('offers/getData', async () => {
    const response = await instance.get('/offers')
    return response.data
})

export const getOffer = createAsyncThunk('offers/getOffer', async (id) => {
    const response = await instance.get(`/offers/${id}`)
    return response.data
})

export const addOffer = createAsyncThunk('offers/addOffer', async (offer, { dispatch, getState }) => {
    await instance.post(`/offers`, offer)
    await dispatch(getData(getState().offers.params))
    return offer
})

export const updateOffer = createAsyncThunk('offers/updateOffer', async (offer, { dispatch, getState }) => {
    await instance.put(`/offers/${offer.id}`, {
        price: offer.price,
        days: offer.days,
        OfferId: offer.OfferId,
        SubCategoryId: offer.SubCategoryId
    })
    await dispatch(getData(getState().ads.params))
    return id
})

export const deleteOffer = createAsyncThunk('offers/deleteOffer', async (id, { dispatch, getState }) => {
    await instance.delete(`/offers/${id}`)
    await dispatch(getData(getState().offers.params))
    return id
})

export const getAllOffers = createAsyncThunk('offers/getAllOffers', async () => {
    const response = await instance.get('/all/offers')
    return response.data.result
})
export const subCatOptions = createAsyncThunk('offers/subCatOptions', async () => {
    const response = await instance.get('/subcat')
    return response.data.result
})

export const offersSlice = createSlice({
    name: 'offers',
    initialState: {
        params: {},
        data: [],
        selectedOffer: null,
        result: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
            })
            .addCase(getOffer.fulfilled, (state, action) => {
                state.selectedOffer = action.payload
            })
    }
})

export default offersSlice.reducer
