
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const API_QR_URL = `${process.env.REACT_APP_API_URL}/client/auth/qr`
const API_LINKS_URL = `${process.env.REACT_APP_API_URL}/links/ad`

export const getQrBaseCode = createAsyncThunk('chatBot/getQrBaseCode', async () => {
    const response = await axios.get(API_QR_URL)
    return response.data.qrBase
})

export const getGeneratedLinks = createAsyncThunk('chatBot/getGeneratedLinks', async () => {
    const response = await axios.get(API_LINKS_URL)
    return response
})

export const chatBotSlice = createSlice({
    name: 'chatBot',
    initialState: {
        qr: '',
        //links: [],
        //messages: ''
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getQrBaseCode.fulfilled, (state, action) => {
                state.qr = action.payload
            })
            .addCase(getGeneratedLinks.fulfilled, (state, action) => {
                state.links = action.payload
            })
    }
})

export default chatBotSlice.reducer
