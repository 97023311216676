import jwt from 'jsonwebtoken'

const apiKey = () => {
    const token = jwt.sign(
        { api_key: process.env.REACT_APP_API_KEY },
        process.env.REACT_APP_API_KEY_SECRET,
        { expiresIn: '2min' }
    )

    return token
}

export default apiKey;