
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'

export const getData = createAsyncThunk('payment/getDate', async () => {
    const response = await instance.get('/payments')
    return response.data
})

export const getPayment = createAsyncThunk('payment/getPayment', async (id) => {
    const response = await instance.get(`payments/${id}`)
    return response.data
})

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        data: [],
        selectedPayment: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
            })
            .addCase(getPayment.fulfilled, (state, action) => {
                state.selectedPayment = action.payload
            })
    }
})

export default paymentSlice.reducer
