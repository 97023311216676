import apiKey from '../xkey';
import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_URL}`

let token;
localStorage && localStorage.getItem('user') ? token = JSON.parse(localStorage.getItem('user')).accessToken : null;

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Authorization': 'Bearer ' + token,
        "x-api-key": apiKey()
    }
})

export default instance