// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '@instance'

export const getAllData = createAsyncThunk('appTickets/getAllData', async () => {
    const response = await instance.get('/tickets')
    return response.data.allData
})

export const getData = createAsyncThunk('appTickets/getData', async (params) => {
    const { page, q, status, priority } = params
    const response = await instance.get(`/tickets?page=${page}&&q=${q}&&status=${status}&&priority=${priority}`)
    return {
        params,
        data: response.data.data,
        total: response.data.total
    }
})

// Get, Add, Delete ticket
export const getTicket = createAsyncThunk('appTickets/getTicket', async id => {
    const response = await instance.get(`/tickets/${id}`)
    return response.data
})
export const addTicket = createAsyncThunk('appTickets/addTicket', async (ticket, { dispatch, getState }) => {
    await instance.post('/tickets', ticket)
    await dispatch(getData(getState().ticket.params))
    await dispatch(getAllData())
    return ticket
})
export const deleteTicket = createAsyncThunk('appTickets/deleteTicket', async (id, { dispatch, getState }) => {
    await instance.delete('tickets', { id })
    await dispatch(getData(getState().tickets.params))
    await dispatch(getAllData())
    return id
})

export const appTicketsSlice = createSlice({
    name: 'appTickets',
    initialState: {
        params: {},
        data: [],
        allData: [],
        total: {},
        selectedTicket: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getAllData.fulfilled, (state, action) => {
                state.allData = action.payload
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.params = action.payload.params
                state.data = action.payload.data
                state.total = action.payload.total
            })
            .addCase(getTicket.fulfilled, (state, action) => {
                state.selectedTicket = action.payload
            })
    }
})

export default appTicketsSlice.reducer